import React, { useCallback, useEffect, useState } from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet-async"

import Seo from "../components/seo/seo"
import Layout from "../components/layout"
import ListingPageContent from "../components/listingPageContent/listingPageContent"
import Section from "../components/section/section"
import CtaEduWork from "../components/ctaEduWork/ctaEduWork"
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs"
import { parseMarket } from "../consts/markets"
import { useTranslation } from "../helpers/useTranslation"
import { getHreflang, getPagePath } from "../helpers/getHreflang"
import { generateTranslations } from "../helpers/generateTranslations"

import { client } from "../graphql/client"

const translations = generateTranslations("blog")

const BlogListing = ({ data, pageContext }) => {
  const { content, seo } = data.sanityBlogListing.tabs
  const categories = data.allSanityBlogCategory
  const posts = data.allSanityBlogPost
  const { currentPage, totalPages, market } = pageContext
  const link = market === "en-us" ? "blog" : `${parseMarket(market)}/blog`
  const { t } = useTranslation()
  const pagePath = getPagePath()
  const hreflangAbbr = getHreflang()
  const [blogSearchKeys, setBlogSearchKeys] = useState([])
  const [selectedBySearchKeyData, setSelectedBySearchKeyData] = useState()
  const [dynamicPage, setDynamicPage] = useState(1)

  const getSearchKeys = useCallback(async () => {
    client
      ?.fetch(` *[_type == "blogSearchKeys" && market == "${market}"]`)
      .then(data => {
        setBlogSearchKeys(data)
      })
      .catch(error => {
        console.error("Error:", error)
      })
  }, [market])

  const getBlogPostsBySearchKey = useCallback(
    async value => {
      const groqQuery = `*[_type == "blogPost" && tabs.content.market == "${market}" && [tabs.content.title, tabs.content.excerpt, tabs.content.searchKey] match "${value}"]{
      tabs{
        content{
          ...,
          title,
          slug{
            current
          },
          category->{
            slug{
              current
            },
            title
          },
          featuredImage{
            ...,
            asset{
              ...,
              assetId,
              extension,
              id,
              internal {
                ...,
              },
              metadata,
              path,
            }
          },
          searchKey,
          excerpt,
          _rawFeaturedImage->{
            ...,
            alt,
            asset{
              ...,
              assetId,
              extension,
              id,
              internal {
                ...,
              },
              metadata,
              path,
            },
            title,
            _type
          }
        }
      }
    }`

      try {
        const response = await client.fetch(groqQuery)
        setSelectedBySearchKeyData(response)
        setDynamicPage(1)
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    },
    [market]
  )

  const onSearchKeySelect = useCallback(
    value => {
      getBlogPostsBySearchKey(value?.label)
      setDynamicPage(1)
    },
    [getBlogPostsBySearchKey]
  )

  useEffect(() => {
    getSearchKeys()
  }, [getSearchKeys])

  return (
    <Layout translations={translations}>
      <Helmet>
        <link rel="alternate" href={pagePath} hreflang={hreflangAbbr} />
        <link
          rel="alternate"
          href={
            market === "es"
              ? pagePath.replace("/es/blog", "/au/blog")
              : market === "en-au"
              ? pagePath.replace("/au/blog", "/es/blog")
              : market === "en-us"
              ? pagePath.replace("/blog", "/au/blog")
              : market === "pt"
              ? pagePath.replace("/pt/blog", "/blog")
              : pagePath
          }
          hreflang={
            market === "es"
              ? "en-au"
              : market === "en-au"
              ? "es"
              : market === "en-us"
              ? "en-au"
              : market === "pt"
              ? "en"
              : "en"
          }
        />
        <link
          rel="alternate"
          href={
            market === "es"
              ? pagePath.replace("/es/blog", "/blog")
              : market === "en-au"
              ? pagePath.replace("/au/blog", "/blog")
              : market === "en-us"
              ? pagePath.replace("/blog", "/es/blog")
              : market === "pt"
              ? pagePath.replace("/pt/blog", "/es/blog")
              : pagePath
          }
          hreflang={
            market === "es"
              ? "en"
              : market === "en-au"
              ? "en"
              : market === "en-us"
              ? "es"
              : market === "pt"
              ? "es"
              : "en"
          }
        />
        <link
          rel="alternate"
          href={
            market === "es"
              ? pagePath.replace("/es/blog", "/pt/blog")
              : market === "en-au"
              ? pagePath.replace("/au/blog", "/pt/blog")
              : market === "en-us"
              ? pagePath.replace("/blog", "/pt/blog")
              : market === "pt"
              ? pagePath.replace("/pt/blog", "/au/blog")
              : pagePath
          }
          hreflang={
            market === "es"
              ? "pt"
              : market === "en-au"
              ? "pt"
              : market === "en-us"
              ? "pt"
              : market === "pt"
              ? "en-au"
              : "en"
          }
        />
        <link
          rel="alternate"
          href={
            market === "es"
              ? pagePath.replace("/es/blog", "/blog")
              : market === "en-au"
              ? pagePath.replace("/au/blog", "/blog")
              : market === "pt"
              ? pagePath.replace("/pt/blog", "/blog")
              : pagePath
          }
          hreflang="x-default"
        />
      </Helmet>
      <Seo
        title={content.hero}
        breadcrumbs={[
          {
            link,
            title: "Blog",
          },
        ]}
        path={pageContext.pagePath}
        {...seo}
      />
      <Breadcrumbs homePageLabel={t("blog")} steps={[{ label: t("blog"), path: "*" }]} />
      <ListingPageContent
        blogSearchKeys={blogSearchKeys}
        dynamicPage={dynamicPage}
        title={content.hero}
        items={posts}
        currentPage={currentPage}
        totalPages={totalPages}
        categories={categories}
        baseUrl={link}
        market={market}
        onSearchKeySelect={onSearchKeySelect}
        selectedBySearchKeyData={selectedBySearchKeyData}
        setDynamicPage={setDynamicPage}
      />
      <Section background="grey">
        <CtaEduWork {...content._rawCtaEduWork} />
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query ($skip: Int!, $limit: Int!, $market: String!, $headerId: String!) {
    sanityBlogListing(_id: { regex: $headerId }) {
      tabs {
        content {
          hero
          _rawCtaEduWork
        }
        seo {
          canonical
          description
          metaTitle
          noIndex
          openGraphImage {
            asset {
              url
            }
          }
          removeSitemap
        }
      }
    }
    allSanityBlogPost(
      filter: { tabs: { content: { market: { eq: $market }, category: { title: { ne: "ethink" } } } } }
      sort: { fields: tabs___content___publishDate, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        tabs {
          content {
            title
            category {
              slug {
                current
              }
            }
            slug {
              current
            }
            excerpt
            _rawFeaturedImage(resolveReferences: { maxDepth: 10 })
          }
        }
      }
      group(field: tabs___content___category___id) {
        totalCount
        fieldValue
      }
    }
    allSanityBlogCategory(filter: { market: { eq: $market }, title: { ne: "ethink" } }) {
      nodes {
        id
        title
        slug {
          current
        }
      }
    }
  }
`

export default BlogListing
